import React, { useState } from 'react';
import { ConsultContext } from '../contexts/consult';
import Switch from '@material-ui/core/Switch'
import './OnlyScore.css'

function OnlyScore({onlyScore, setChecked}) {

    console.log(onlyScore)

    return (
        <div className='onlyScoreContainer'>
            <div className='title'>
                <p>Apenas Score</p>
            </div>
            <div className=''>
                <Switch onlyScore={onlyScore === 1} onChange={(event) => setChecked(event.target.checked ? 1 : 0)} color="primary" />
            </div>
        </div>
    );
}

export default OnlyScore;