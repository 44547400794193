import React from 'react'
import { useEffect, useContext, useState } from 'react'
import { Chart } from 'react-google-charts'
import './Graphic.css'


function Graphic({ list }) {

    const totalCount = list.length;
    const scoreCount = list.reduce((count, item) => item.score ? count + 1 : count, 0);
    const emptyScoreCount = list.reduce((count, item) => !item.score ? count + 1 : count, 0);
    const scorePercentage = ((scoreCount / totalCount) * 100).toFixed(2);

    const data = [
        ["Category", "Count"],
        ["Finalizadas", scoreCount],
        ["Pendentes", emptyScoreCount],
    ];

    const options = {
        pieHole: 0.5,
        is3D: false,
        colors: ["#0F3E78", "#9d9d9d"],
        legend: {
            position: "bottom",
            alignment: "center",
        },
        pieSliceText: "none"
    };

    if (list.length === 0) {
        return <div className='emptyDataGraphic'>Não há dados para exibir</div>;
    }

    return (
        <div className='graphicContainer'>
            <div className='graphicTitles'>
                <p>Total de Análises: {totalCount}</p>
                <p>Finalizadas: {scoreCount}</p>
                <p>Pendentes: {emptyScoreCount}</p>
            </div>
            <div className='graphic'>
                <Chart
                    chartType="PieChart"
                    max-width="200px"
                    max-height="300px"
                    data={data}
                    options={options}
                />
            </div>
        </div>
    )

}

export default Graphic