import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from 'react-router-dom';
import { api, identity, createSession, download } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [login, setUserName] = useState(null);
    //const [password, setPassword] = useState(null);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const recoveredUser = localStorage.getItem('user');
        const token = localStorage.getItem('token');

        if (recoveredUser) {
            setUserName(JSON.parse(recoveredUser))
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }

        setLoading(false);

    }, []);

    const userLogin = async (login, password) => {
        localStorage.clear();
        try {
            const response = await createSession(login, password);

            console.log(response.data);

            const token = response.data.accessToken

            localStorage.setItem("user", JSON.stringify(login));
            localStorage.setItem("token", token);

            api.defaults.headers.Authorization = `Bearer ${token}`;

            setUserName(login)
            navigate("/");
        } catch (error) {
            console.error(error);
            throw new Error("Usuário e/ou senha incorreto(s), tente novamente.");
        }
    
    }

    function logout() {
        console.log('logout')
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.clear();
        api.defaults.headers.Authorization = null;
        setUserName(null);
        navigate("/Login");
    }
    

    return (
        <AuthContext.Provider value={{ authenticated: !!login, login, loading, userLogin, logout }}>
            {children}
        </AuthContext.Provider>
    )

}

//grava contextos globais da aplicação