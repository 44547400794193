import React, { useState } from 'react';
import './PartnerFilter.css'

function PartnerFilter({partnerId, setPartnerId}) {
    

    return (
        <div className='partnerFilterContainer'>
            <div className='partnerTitle'>
                <p>Parceiro: </p>
            </div>
            <div className='inputPartner'>
                <input type='number' id='partnerFilter' onChange={(e) => setPartnerId(e.target.value)} placeholder='EX: 1234'></input>
            </div>
        </div>
    );
}

export default PartnerFilter;
