import { AuthContext, AuthProvider } from '../contexts/auth';
import { useEffect, useContext, useState } from 'react'
import './ConsultByDate.css'

function ConsultByDate() {

    // const handleConsultByDate = () => {
    //     //quando houverem mais tipos de consulta, o dash esperará o usuário selecionar um tipo
    // }


    return (
        <div className='consultByDateButtonContainer'>
            <button className='consultByDateButton'>Consulta por Data</button>
        </div>
    );
}

export default ConsultByDate;