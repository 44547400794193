import React from 'react';
import { useEffect, useContext, useState } from 'react'
import './Download.css'
import { saveAs } from 'file-saver';
import { jsonToCSV } from 'react-papaparse';

function Download({ list }) {

    function handleDownload() {

        if (list.length === 0) {
            alert('A lista está vazia, não é possível fazer o download.');
            return;
        }

        let csvData = [];

        if (list[0].hasOwnProperty('bigFive')) {

            csvData = list.map(item => ({
                identifier: item.identifier,
                date: item.date,
                score: item.score,
                extraversion: item.bigFive.extraversion,
                active: item.bigFive.active,
                assertive: item.bigFive.assertive,
                cheerful: item.bigFive.cheerful,
                openness: item.bigFive.openness,
                adventurous: item.bigFive.adventurous,
                artistic: item.bigFive.artistic,
                emotionallyAware: item.bigFive.emotionallyAware,
                imaginative: item.bigFive.imaginative,
                intellectual: item.bigFive.intellectual,
                conscientiousness: item.bigFive.conscientiousness,
                cautious: item.bigFive.cautious,
                disciplined: item.bigFive.disciplined,
                dutiful: item.bigFive.dutiful,
                neuroticism: item.bigFive.neuroticism,
                melancholy: item.bigFive.melancholy,
                selfConscious: item.bigFive.selfConscious,
                authorityChallenging: item.bigFive.authorityChallenging,
                achievementStriving: item.bigFive.achievementStriving,
                orderliness: item.bigFive.orderliness,
                selfEfficacy: item.bigFive.selfEfficacy,
                stressProne: item.bigFive.stressProne,
                agreeableness: item.bigFive.agreeableness,
                cooperative: item.bigFive.cooperative,
                trusting: item.bigFive.trusting,
                excitementSeeking: item.bigFive.excitementSeeking,
                outgoing: item.bigFive.outgoing,
                gregariousness: item.bigFive.gregariousness,
                altruism: item.bigFive.altruism,
                modesty: item.bigFive.modesty,
                uncompromising: item.bigFive.uncompromising,
                sympathy: item.bigFive.sympathy,
                fiery: item.bigFive.fiery,
                proneToWorry: item.bigFive.proneToWorry,
                immoderation: item.bigFive.immoderation,
                positive_probability: item.predictions.find(p => p.prediction === "positive")?.probability || 0,
                negative_probability: item.predictions.find(p => p.prediction === "negative")?.probability || 0,
                sadness_probability: item.predictions.find(p => p.prediction === "sadness")?.probability || 0,
                anger_probability: item.predictions.find(p => p.prediction === "anger")?.probability || 0,
                joy_probability: item.predictions.find(p => p.prediction === "joy")?.probability || 0,
                surprise_probability: item.predictions.find(p => p.prediction === "surprise")?.probability || 0,
                fear_probability: item.predictions.find(p => p.prediction === "fear")?.probability || 0,
                disgust_probability: item.predictions.find(p => p.prediction === "disgust")?.probability || 0,
                no_emotion_probability: item.predictions.find(p => p.prediction === "no-emotion")?.probability || 0,

            })


            );
        } else {
            csvData = list.map(item => ({
                identifier: item.identifier,
                date: item.date,
                score: item.score,
            })

            );
        }

        const csvString = jsonToCSV(csvData);

        const blob = new Blob([csvString], { type: 'text/csv' });

        saveAs(blob, 'analysis.csv');
    }

    return (
        <div className='downloadButtonContainer'>
            <button className='downloadButton' onClick={handleDownload}>Download</button>
        </div>
    );
}

export default Download;