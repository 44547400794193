import './App.css';
import AppRoutes from './AppRoutes';


function App({ token }) {

  return (
    <AppRoutes />
  );
}

export default App;