import React, { useState } from 'react';
import Score from './RegisterScore'
import './Analisys.css'
// import { AllDataAnalysis, OnlyScoreAnalysis } from '../interfaces/Consults';
import ChangeScore from './ChangeScore'
import { format } from 'date-fns';

function TrueFinished({ identifier, date, score}) {
    const status = score ? 'finalizado' : 'pendente';

    const [isOpen, setIsOpen] = useState(false);  
    const [score01, setScore01] = useState("");  

    return (
        <div>
            <div>
                <div className='analisysHeader'>
                    <div className='analisysContent'>
                        <div className='analisysHeaderContent'>
                            <p>Id: {identifier}</p>
                            <p>Data: {format(new Date(date), 'dd/MM/yyyy')}</p>
                            <p>Status: {status}</p>
                            <button className={`expand-button ${isOpen ? 'expanded' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                                <span className={`button-icon ${isOpen ? 'rotated' : ''}`}>{">"}</span></button>
                        </div>
                        {isOpen && (
                            <div className='analisysBodyContent'>
                                <div className='analisysEmotionColunm'>
                                    <p>Score Registrado: {score}</p>
                                </div>
                                <div className='lineComponentStyleContainer'>
                                    <span className='lineComponentStyle'></span>
                                </div>
                                <div className='analisysScoreRegister' onClick={(e) => e.stopPropagation()}>
                                    
                                    <ChangeScore identifier={identifier} score01={score01} setScore01={setScore01}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TrueFinished