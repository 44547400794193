import React, { useState, createContext } from "react";
import {consultAnalysisByDate01, registerScore01, changeScore01 } from '../services/api';

export const ConsultContext = createContext();

export const ConsultProvider = ({ children }) => {
    
    const registerScore02 = async (identifier, score) => {
        try {
            const response = await registerScore01(identifier, score);
            const message = response.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar registrar o score, tente novamente.");
        }
    }

    const changeScore02 = async (identifier, score) => {
        try {
            const response = await changeScore01(identifier, score);
            const message = response.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar registrar o score, tente novamente.");
        }
    }

    const consultAnalysisByDate02 = async (startDate, endDate, partnerId, onlyScore) => {
        try {
            console.log("chegou aqui 02")
            const response02 = await consultAnalysisByDate01(startDate, endDate, partnerId, onlyScore);
            const message = response02.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar consultar as análises, tente novamente. ");
            
        }
    }    

    return (
        <ConsultContext.Provider value={{ registerScore02, changeScore02, consultAnalysisByDate02 }}>
            {children}
        </ConsultContext.Provider>
    )
}
