import { ConsultContext, ConsultProvider } from '../contexts/consult';
import { useEffect, useContext, useState } from 'react'
import './ConsultButton.css'
import { consultAnalysisByDate01 } from '../services/api';
import { AuthContext } from '../contexts/auth';

function Consult({ startDate, endDate, onlyScore, partnerId, setList }) {

    const { logout } = useContext(AuthContext);
    const [loading, setLoading] = useState();
    const { consultAnalysisByDate02 } = useContext(ConsultContext);
    const [errorMessage, setErrorMessage] = useState("");

    const handleConsult = async (event) => {

        event.preventDefault();

        try {
            const response01 = await consultAnalysisByDate01(startDate, endDate, onlyScore, partnerId);
            console.log(response01)
            const result0 = response01.data.result;
            setList(result0);
            setLoading(false);
            return result0;
        } catch (error) {
            console.log(error.message);
            setErrorMessage(error.message);
            if (error.response && error.response.status === 401) {
                window.alert("Sua sessão expirou. Por favor, faça login novamente.");
                logout();
            } else {
                console.error(error);
                window.alert("Estamos com algumas instabilidades, tente novamente mais tarde.");
            }
        }
    };

    return (
        <div className='analisysButtonContainer'>
            <button className='consultAnalisysButton' onClick={handleConsult}>Consultar</button>
        </div>
    );
}

export default Consult;