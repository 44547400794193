import React, { useState } from 'react';
import './Analisys.css'
// import { AllDataAnalysis, OnlyScoreAnalysis } from '../interfaces/Consults';
import ChangeScore from './ChangeScore'
import { format } from 'date-fns';

function FalseFinished({ identifier, date, score, obj }) {
    const status = score ? 'finalizado' : 'pendente';

    const [isOpen, setIsOpen] = useState(false);
    const [score01, setScore01] = useState("");

    function formatPercentage(number) {
        return `${(number * 1).toFixed(2)} %`;
    }

    const translations = {
        "extraVersion": "Extroversão",
        "active": "Atividade",
        "assertive": "Assertividade",
        "cheerful": "Alegria",
        "openness": "Abertura",
        "adventurous": "Aventura",
        "artistic": "Artístico",
        "emotionallyAware": "Consciência Emocional",
        "imaginative": "Imaginação",
        "intellectual": "Intelectual",
        "conscientiousness": "Consciência",
        "cautious": "Cautela",
        "disciplined": "Disciplinado",
        "dutiful": "Dever",
        "neuroticism": "Neuroticismo",
        "melancholy": "Melancolia",
        "selfConscious": "Autoconsciência",
        "authorityChallenging": "Desafio de autoridade",
        "achievementStriving": "Busca por realizações",
        "orderliness": "Ordem",
        "selfEfficacy": "Autoeficácia",
        "stressProne": "Propenso ao estresse",
        "agreeableness": "Agradabilidade",
        "cooperative": "Cooperativo",
        "trusting": "Confiável",
        "excitementSeeking": "Busca por emoção",
        "outgoing": "Sociabilidade",
        "gregariousness": "Gregarismo",
        "altruism": "Altruísmo",
        "modesty": "Modéstia",
        "uncompromising": "Intransigente",
        "sympathy": "Empatia",
        "fiery": "Temperamento",
        "proneToWorry": "Propenso a preocupações",
        "immoderation": "Imoderação",
        "positive": "Positivo",
        "negative": "Negativo",
        "sadness": "Tristeza",
        "anger": "Raiva",
        "joy": "Alegria",
        "surprise": "Surpresa",
        "fear": "Medo",
        "disgust": "Nojo",
        "no-emotion": "Sem emoção"
    }

    function translateEmotionName(name) {
        return translations[name] || name;
    }

    const bigFiveElements = Object.entries(obj.bigFive).map(([key, value]) => (
        <div className="emotions" key={key}>
            <p>{translateEmotionName(key)}:</p>
            <p>{formatPercentage(value)}</p>
        </div>
    ));

    const predictionsElements = obj.predictions.map(prediction => (
        <div className="emotions" key={prediction.prediction}>
            <p>{translateEmotionName(prediction.prediction)}:</p>
            <p>{formatPercentage(prediction.probability)}</p>
        </div>
    ));

    return (
        <div>
            <div>
                <div className='analisysHeader'>
                    <div className='analisysContent'>
                        <div className='analisysHeaderContent'>
                            <p>Id: {identifier}</p>
                            <p>Data: {format(new Date(date), 'dd/MM/yyyy')}</p>
                            <p>Status: {status}</p>
                            <button className={`expand-button ${isOpen ? 'expanded' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                                <span className={`button-icon ${isOpen ? 'rotated' : ''}`}>{">"}</span></button>
                        </div>
                        {isOpen && (
                            <div className='analisysBodyContent'>
                                <div className='analisysEmotionColunm'>
                                    <div className="emotionsTitle">
                                        <p>Descrição</p>
                                        <p>Valor</p>
                                    </div>
                                    {bigFiveElements}
                                    {predictionsElements}
                                </div>
                                <div className='lineComponentStyleContainer'>
                                    <span className='lineComponentStyle'></span>
                                </div>
                                <div className='analisysScoreRegister' onClick={(e) => e.stopPropagation()}>
                                    <p>Score Registrado: {score}</p>
                                    <div className='lineComponentStyleContainer'>
                                        <span className='lineComponentStyle'></span>
                                    </div>
                                    <ChangeScore identifier={identifier} score01={score01} setScore01={setScore01}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FalseFinished

