import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import { AuthProvider, AuthContext } from './contexts/auth';
import { useState, useContext } from 'react'
import Consult from './components/ConsultButton';
import { ConsultProvider } from './contexts/consult';

function AppRoutes() {
    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return <div className='loading'>Carregando...</div>
        }

        if (!authenticated) {
            return <Navigate to="/Login" />
        }

        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <ConsultProvider>
                    <Routes>
                        <Route exact path="/Login" element={<Login />} />
                        <Route exact path="/" element={<Private><Dashboard /></Private>} />
                    </Routes>
                </ConsultProvider>
            </AuthProvider>
        </Router>
    );

}

export default AppRoutes;