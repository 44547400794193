import { useState, useEffect } from 'react'
import styles from './Dashboard.module.css'
import DateFilter from "../../components/DateFilter";
import OnlyScore from "../../components/OnlyScore";
import Download from "../../components/Download";
import PartnerFilter from "../../components/PartnerFilter";
import Consult from "../../components/ConsultButton";
import Logout from "../../components/Logout";
import ConsultByDate from "../../components/ConsultByDate";
import TrueFinished from '../../components/TrueFinished';
import FalseFinished from '../../components/FalseFinished';
import FalsePending from '../../components/FalsePending';
import Graphic from '../../components/Graphic';
import logoVert from '../../Icons/logo-vert-branco.png'

function Dashboard() {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [onlyScore, setChecked] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  if (loading) {
    <div className='loading'>Carregando análises...</div>;
  }

  return (
    <>
      <div className={styles.dashboardContainer}>
        <div className={styles.sidebarContainer}>
          <div className={styles.logotype}>
            <img src={logoVert} alt="logo" className={styles.logo} />
          </div>
          <div className={styles.dashboardButtons}>
            <div className={styles.consultButtons}>
              <ConsultByDate />
            </div>
            <span className={styles.sidebarFilterSeparator}></span>
            <div className=''>
              <Logout />
            </div>
          </div>
        </div>
        <div className={styles.analisysContainer}>
          <div className={styles.headersContainer}>
            <div className={styles.filtersContainer}>
              <DateFilter setStartDate={setStartDate} setEndDate={setEndDate} />
              <PartnerFilter setPartnerId={setPartnerId} />
              <OnlyScore onlyScore={onlyScore} setChecked={setChecked} />
              <span className={styles.filterSeparator}></span>
              <Consult startDate={startDate} endDate={endDate} onlyScore={onlyScore} partnerId={partnerId} setList={setList} />
              {list.length > 0 ? (
                <>
                  <Download list={list} />
                </>
              ) : (<></>)}
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.analisysDataContainer}>
              <div className={styles.analisysDataContent}>
                {
                  list.map((obj) => {
                    switch (true) {
                      case obj.hasOwnProperty('bigFive') && obj.score === "":
                        return <FalsePending obj={obj} key={obj.identifier} identifier={obj.identifier} date={obj.date} score={obj.score} />;
                      case obj.hasOwnProperty('bigFive') && obj.score !== "":
                        return <FalseFinished obj={obj} key={obj.identifier} identifier={obj.identifier} date={obj.date} score={obj.score} />;
                      // case !obj.hasOwnProperty('bigFive') && obj.score === "":
                      //   return <TruePending obj={obj} key={obj.identifier} identifier={obj.identifier} date={obj.date} score={obj.score} />;
                      case !obj.hasOwnProperty('bigFive') && obj.score !== "":
                        return <TrueFinished obj={obj} key={obj.identifier} identifier={obj.identifier} date={obj.date} score={obj.score} />;
                      default:
                        return null;
                    }
                  })
                }
              </div>
              {list.length > 0 ? (
                <>
                  <div className={styles.lineComponentStyleContainer}>
                    <span className={styles.lineComponentStyle}></span>
                  </div>
                  <div className={styles.downloadAnalysisContainer}>
                    <p>Deseja fazer o download deste relatório?</p>
                    <div className={styles.downloadButtonContainer}>
                      <Download list={list} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.textDownloadButtonContainer}>Sem resultados</p>
                </>
              )}
            </div>
            <div className={styles.generalDataContainer}>
              <div className={styles.generalDataTitle}>
                <h2>Visão Geral</h2>
              </div>
              <div className={styles.generalDataList}>
                <Graphic list={list} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;