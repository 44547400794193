import React, { useState, useEffect } from 'react';
import './DateFilter.css'
import { parseISO, format } from 'date-fns';

function DateFilter({ setStartDate, setEndDate }) {
    const formatDate = (date) => {
        if (!date) {
            return ''; // Retorna uma string vazia se o valor estiver vazio
        }
        const selectedDate = parseISO(date);
        const formattedDate = format(selectedDate, 'dd/MM/yyyy');
        return formattedDate;
    };

    return (
        <div className="dateContainer">
            <div className="dateTitle">
                <p>Período:</p>
            </div>
            <div className="inputDate">
                <input
                    type="date"
                    id="startDate"
                    onChange={(e) => setStartDate(formatDate(e.target.value))}
                    placeholder="DD/MM/YYYY"
                />
                <span className="filterSeparator"></span>
                <input
                    type="date"
                    id="sendDate"
                    onChange={(e) => setEndDate(formatDate(e.target.value))}
                    placeholder="DD/MM/YYYY"
                />
            </div>
        </div>
    );
}


export default DateFilter;

