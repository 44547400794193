import styles from './Login.module.css'
import { useState, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';
import logoHoriz from '../../Icons/logo-horiz-azul.png'


function Login() {

    const [login, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { userLogin } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {

        event.preventDefault();

        try {
            await userLogin(login, password);
        } catch (error) {
            console.log(error.message);
            setErrorMessage(error.message);
        }

    };

    return (

        <div className={styles.loginContainer}>
            {/* <div className={styles.desktopWarning}>
                Esta aplicação é otimizada para a versão desktop e pode não funcionar corretamente em telas menores ou televisores.
            </div> */}
            <form onSubmit={handleSubmit} className={styles.formContainer} id='teste'>
                <div className={styles.loginlogo}>
                    <img src={logoHoriz} alt="logo" className={styles.logo} />
                </div>
                <div >
                    <span className={styles.loginTitle}>Seja bem-vindo!</span>
                </div>
                <div>
                    {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                </div>
                <div className={styles.wrapInput}>
                    {/* <label htmlFor="email">E-mail:</label> */}
                    <input type="email" className={login !== "" ? `${styles.hasVal} ${styles.input}` : `${styles.input}`} value={login} onChange={e => setUserName(e.target.value)} required />
                    <span className={styles.focusInput} data-placeholder="Email"></span>
                </div>
                <div className={styles.wrapInput}>
                    {/* <label htmlFor="password">Senha:</label> */}
                    <input type="password" className={password !== "" ? `${styles.hasVal} ${styles.input}` : `${styles.input}`} value={password} onChange={e => setPassword(e.target.value)} required />
                    <span className={styles.focusInput} data-placeholder="Password"></span>
                </div>
                <div className={styles.containerLoginFormBtn}>
                    <input type="submit" value="Entrar" className={styles.loginFormBtn} />
                </div>
            </form>
        </div>
    )

}

export default Login