import { AuthContext, AuthProvider } from '../contexts/auth';
import { useEffect, useContext, useState } from 'react'
import './Logout.css'

function Logout() {
    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
    }


    return (
        <div className='logoutButtonContainer'>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default Logout;