import './RegisterScore.css'
import { useState, useContext } from 'react'
import { ConsultContext, ConsultProvider } from '../contexts/consult';
import { registerScore01 } from '../services/api';

function Score({identifier, score01, setScore01}) {

    // const [score, setScore] = useState("");
    const { registerScore02 } = useContext(ConsultContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const handleScore = async () => {

        // event.preventDefault();
        
        try {
            console.log(identifier, score01)
            const response2 = await registerScore01(identifier, score01);
            console.log(response2)
            setMessage('Score registrado com sucesso!')
            window.alert("Score registrado com sucesso!")
        } catch (error) {
            setErrorMessage(error.message);
            console.log(error.message);
            window.alert("Ocorreu um erro ao tentar registrar o score, tente novamente mais tarde.");
        }

    };

    return (
        <div className='analisysContainer'>
            <div className='titleScoreRegister'>
                <div>
                    {message && <div className='scoreMessage'>{message}</div>}
                </div>
                <p>Registre abaixo a pontuação desta análise:</p>
            </div>
            <div className='buttonsAnalisysContainer'>
                <div className='scoreInputContainer'>
                    <p>Score:</p>
                    <input type="text" className='scoreInput' placeholder='Ex: 1000'
                        onFocus={(e) => e.stopPropagation()} onChange={e => setScore01(e.target.value)} />
                </div>
                <div className='scoreButtonContainer'>
                    <button className='scoreButton' onFocus={(e) => e.stopPropagation()} onClick={(e) => handleScore()}>Registrar</button>
                </div>
            </div>
        </div>
    )

}

export default Score