import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://analytics.sinapsys.ai'
});

export const identity = axios.create({
  baseURL: 'https://identitydashboard.sinapsys.ai'
});

export const createSession = async (login, password) => {
  const data = { login, password };
  return identity.post("/login", data);
};

export const registerScore01 = async (identifier, score) => {
  const data = { identifier, score };
  return api.post("/api/v1/dashboard/registerScore", { identifier, score } )
}

export const changeScore01 = async (identifier, score) => {
  const data = { identifier, score };
  return api.put("/api/v1/dashboard/changeScore", { identifier, score } )
}

export const consultAnalysisByDate01 = async (startDate, endDate, onlyScore, partnerId) => {
  const data = { startDate, endDate, partnerId, onlyScore };
  return api.post("/api/v1/dashboard/consultAnalysisByDate", data);
}

export const consultAnalysisByPartner01 = async (partnerId, onlyScore) => {
  const data = {partnerId, onlyScore};
  return api.post("/api/v1/dashboard/consultAnalysisByPartnerId", data)
}